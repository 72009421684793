export function hashCode(str) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

export const languages = {
  afrikaans: 'af',
  albanian: 'sq',
  aragonese: 'an',
  armenian: 'hy',
  armenian_west: 'hy-west',
  bosnian: 'bs',
  brazil: 'pt-br',
  bulgarian: 'bg',
  cantonese: 'zh-yue',
  catalan: 'ca',
  croatian: 'hr',
  czech: 'cs',
  danish: 'da',
  default: 'en',
  dutch: 'nl',
  en_scottish: 'en-sc',
  en_westindies: 'en-wi',
  english: 'en-gb',
  english_north: 'en-uk-north',
  english_rp: 'en-uk-rp',
  english_us: 'en-us',
  english_wmids: 'en-uk-wmids',
  esperanto: 'eo',
  estonian: 'et',
  finnish: 'fi',
  french: 'fr-fr',
  french_Belgium: 'fr-be',
  georgian: 'ka',
  german: 'de',
  greek: 'el',
  greek_ancient: 'grc',
  hindi: 'hi',
  hungarian: 'hu',
  icelandic: 'is',
  indonesian: 'id',
  irish_gaeilge: 'ga',
  italian: 'it',
  kannada: 'kn',
  kurdish: 'ku',
  latin: 'la',
  latvian: 'lv',
  lingua_franca_nova: 'lfn',
  lithuanian: 'lt',
  lojban: 'jbo',
  macedonian: 'mk',
  malay: 'ms',
  malayalam: 'ml',
  Mandarin: 'zh',
  nepali: 'ne',
  norwegian: 'no',
  persian: 'fa',
  persian_pinglish: 'fa-pin',
  polish: 'pl',
  portugal: 'pt-pt',
  punjabi: 'pa',
  romanian: 'ro',
  russian: 'ru',
  serbian: 'sr',
  slovak: 'sk',
  spanish: 'es',
  spanish_latin_am: 'es-la',
  swahili_test: 'sw',
  swedish: 'sv',
  tamil: 'ta',
  turkish: 'tr',
  vietnam: 'vi',
  vietnam_hue: 'vi-hue',
  vietnam_sgn: 'vi-sgn',
  welsh: 'cy'
};
